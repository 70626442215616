<footer class="footer-area">
    <div class="container">

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
                    <p class="copy-right">
                        <i class='bx bx-copyright'></i> {{currentYear}} Upskilling Guru Centre Of Excellence
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>