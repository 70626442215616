import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    classApplied = false;
    showCourses = false;
    showAwsCloud = false;
    showGCPCloud = false;
    showAzureCloud = false;
    showDataScience = false;
    showFullStackDevelopment = false;
    showRestAPI = false;
    showDevOps = false;
    showHybridDevelopment = false;
    showBlockchain = false;
    showRPA = false;
    showBigdata = false;

    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    toggleCourses() {
        this.showCourses = !this.showCourses;
        this.showAwsCloud = false;
        this.showGCPCloud = false;
        this.showAzureCloud = false;
        this.showDataScience = false;
        this.showFullStackDevelopment = false;
        this.showRestAPI = false;
        this.showDevOps = false;
        this.showHybridDevelopment = false;
        this.showBlockchain = false;
        this.showRPA = false;
        this.showBigdata = false;
    }

    toggleAWSCloud() {
        if (!this.showAwsCloud) {
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showAwsCloud = !this.showAwsCloud;
    }

    toggleGCPCloud() {
        if (!this.showGCPCloud) {
            this.showAwsCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showGCPCloud = !this.showGCPCloud;
    }

    toggleAzureCloud() {
        if (!this.showAzureCloud) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showAzureCloud = !this.showAzureCloud;
    }

    toggleDatascience() {
        if (!this.showDataScience) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showDataScience = !this.showDataScience;
    }

    toggleFullstackDevelopment() {
        if (!this.showFullStackDevelopment) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showFullStackDevelopment = !this.showFullStackDevelopment;
    }

    toggleRestAPI() {
        if (!this.showRestAPI) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showRestAPI = !this.showRestAPI;
    }

    toggleDevOps() {
        if (!this.showDevOps) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showDevOps = !this.showDevOps;
    }

    toggleHybridMobileDevelopment() {
        if (!this.showHybridDevelopment) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showBlockchain = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showHybridDevelopment = !this.showHybridDevelopment;
    }

    toggleBlockChain() {
        if (!this.showBlockchain) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showRPA = false;
            this.showBigdata = false;
        }
        this.showBlockchain = !this.showBlockchain;
    }

    toggleRPA() {
        if (!this.showRPA) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showBlockchain = false;
            this.showBigdata = false;
        }
        this.showRPA = !this.showRPA;
    }

    toggleBigData() {
        if (!this.showBigdata) {
            this.showAwsCloud = false;
            this.showGCPCloud = false;
            this.showAzureCloud = false;
            this.showDataScience = false;
            this.showFullStackDevelopment = false;
            this.showRestAPI = false;
            this.showDevOps = false;
            this.showHybridDevelopment = false;
            this.showRPA = false;
            this.showBlockchain = false;
        }
        this.showBigdata = !this.showBigdata;
    }
}