<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <h2>Page under contruction</h2>
                <h4 style="padding-top: 30px;">We are updating our website. We look forward to serving your needs soon</h4>
                <form class="newsletter-form">
                    <button routerLink="/contact" class="default-btn contct-btn">Contact Us<span></span></button>
                </form>
            </div>
        </div>
    </div>
</div>