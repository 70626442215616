<div class="page-title-area">
    <div class="container" style="height: 60px;">
        <div class="header-dv">
            <h2 style="text-align: center;" data-aos="zoom-out-up" data-aos-delay="50" data-aos-duration="1000"><span class="page-title-content-span">Let's talk!</span></h2>
        </div>
    </div>
</div>

<div class="contact-area ptb-40">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="contact-info">
                    <ul>
                        <!-- <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h5>Our Address</h5>
                            <p>DNO: 23/513, Nagalakatta Street, Jammalamadugu, Kadapa District, Andhra Pradesh-516434</p>
                        </li> -->
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h5>Contact</h5>
                            <!-- <p>Mobile: <a href="tel:+91-9505486423">+91-9505486423</a></p> -->
                            <p>Mail: <a href="mailto:upskilling.guru@gmail.com">upskilling.guru@gmail.com</a></p>
                            <!-- <p>Mail 2: <a href="mailto:prashikshan@upskilling.guru">prashikshan@upskilling.guru</a></p> -->
                        </li>
                        <!-- <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h5>Hours of Operation</h5>
                            <p>Monday - Saturday: 09:00 AM - 06:00 PM</p>
                            <p>Sunday: 10:00 AM- 02:00 PM</p>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>