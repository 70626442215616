<div class="about-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
                            <div class="image full-height">
                                <div class="vertical-center">
                                    <div>
                                        <span class="back">
                                        <span>A</span>
                                        </span>
                                        <span class="back" style="margin-left: 8px;">
                                            <span>G</span>
                                        <span>O</span>
                                        <span>A</span>
                                        <span>L</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="back">
                                        <span>W</span>
                                        <span>I</span>
                                        <span>T</span>
                                        <span>H</span>
                                        </span>
                                        <span class="back" style="margin-left: 8px;">
                                            <span>O</span>
                                        <span>U</span>
                                        <span>T</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="back">
                                        <span>P</span>
                                        <span>L</span>
                                        <span>A</span>
                                        <span>N</span>
                                        </span>
                                        <span class="back" style="margin-left: 8px;">
                                            <span>I</span>
                                        <span>S</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="back">
                                        <span>J</span>
                                        <span>U</span>
                                        <span>S</span>
                                        <span>T</span>
                                        </span>
                                        <span class="back" style="margin-left: 8px;">
                                            <span>W</span>
                                        <span>I</span>
                                        <span>S</span>
                                        <span>H</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
                            <div class="image">
                                <div class="page">
                                    <span class="strike">
                                        <span class="layoff">
                                            Layoff
                                        </span>
                                    </span>
                                    <br/>
                                    <span class="upskill">
                                        Upskill
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
                            <div class="image">
                                <img style="margin: 15px; width: 250px" src="assets/img/upskill/img-3.jpg" alt="image">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
                            <div class="image full-height">
                                <div class="vertical-center">
                                    <div>
                                        <span class="back">
                                        <span>G</span>
                                        <span>E</span>
                                        <span>T</span>
                                        </span>
                                        <span class="back" style="margin-left: 8px;">
                                            <span>Y</span>
                                        <span>O</span>
                                        <span>U</span>
                                        <span>R</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="back">
                                        <span>F</span>
                                        <span>U</span>
                                        <span>T</span>
                                        <span>U</span>
                                        <span>R</span>
                                        <span>E</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="back">
                                        <span>S</span>
                                        <span>T</span>
                                        <span>A</span>
                                        <span>R</span>
                                        <span>T</span>
                                        <span>E</span>
                                        <span>D</span>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="back">
                                        <span>H</span>
                                        <span>E</span>
                                        <span>R</span>
                                        <span>E</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h4>Develop Your Skills, Learn Something New, and Grow Your Skills From Anywhere in the World!</h4>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <ul class="features-list" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
                        <li><span>Expert Trainers</span></li>
                        <li><span>Cutting Edge Curriculam</span></li>
                        <li><span>Remote Learning</span></li>
                        <li><span>Quiz & Assignments</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>