import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import AOS from 'aos';

import aws_practioner from './curriculam/cloud/aws/aws-practitioner';
import aws_devops from './curriculam/cloud/aws/aws-devops';

@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss']
})
export class CourseContentomponent implements OnInit {
  course_content: any;
  private sub: any;
  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    AOS.init();
    this.sub = this.route.params.subscribe(params => {
      if (params['code'] === 'aws-cloud-practitioner') {
        this.course_content = aws_practioner;
      } else if (params['code'] === 'aws-devops-engineer') {
        this.course_content = aws_devops;
      }
   });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getModuleName(module, index) {
    return `${index+1}.  ${module.title}`;
  }
}
