export default {
    title: "AWS DevOps Certification Training",
    description: "In this course, you will learn the fundamental concepts of DevOps and why it is becoming popular in the IT industry. You will learn about DevOps cultural philosophies, practices, and tools to increase your organization’s ability to develop, deliver, and maintain applications and services at high velocity. This course will teach you how to leverage DevOps technologies in Cloud using AWS and excel for your next role as a DevOps practitioner.",
    learningoutcomes: [
        {
            title: "Gain a solid understanding of what DevOps is all about and how it will help the SDLC"
        },
        {
            title: "Understand DevOps Engineering on AWS"
        },
        {
            title: "Ready to attempt DevOps Foundation(DOFD) exam",
            link: "https://devopsinstitute.com/certifications/devops-foundation/"
        },
        {
            title: "Ready to attempt AWS Certified DevOps Engineer - Professional (DOP-C01) exam",
            link: "https://aws.amazon.com/certification/certified-devops-engineer-professional/"
        }
    ],
    courserequirements: [
        "Familiarity with IT terminology and IT related work knowledge is required",
        "Basic knowledge of administering Linux or Windows systems at the command-line level",
    ],
    coursestructure: [
        {
            title: "This is an eLearning course with interactive live sessions"
        },
        {
            title: "Course Duration",
            description: "6 Weeks (Monday - Friday daily 2 hours)"
        }
    ],
    modules: [
        {
            title: "Introduction to DevOps",
            lessons: [
                "What is SDLC?",
                "A Story of DevOps vs. Traditional Silos",
                "The origin and history of DevOps",
                "DevOps Stakeholders",
                "Problems That DevOps Solves",
                "DevOps Lifecycle",
                "Core DevOps Principles",
                "Key DevOps Practices",
                "DevOps relationship to Agile, Lean and ITSM",
                "Automation & Architecting DevOps Toolchains",
                "Roles, Responsibilities, and Skills of a DevOps Engineer",
                "Quiz",
            ]
        },
        {
            title: "Containerization with Docker",
            lessons: [
                "What & Why is Containerization?",
                "Docker Architecture",
                "Docker Installations & Configurations",
                "Docker Components",
                "How to manage Docker Containers",
                "Create and Managing Docker Images",
                "Docker Hub registry",
                "Docker Networking",
                "Docker Volumes",
                "Quiz & Hands-on-Labs"

            ]
        },
        {
            title: "Version Control – GIT",
            lessons: [
                "Introduction to Version Control Systems",
                "Different types of Version Control Systems",
                "Git lifecycle",
                " Install and Configure Git",
                "Initialize a New Git Repository",
                "Create an Account on GitHub",
                "Push a Git Repository to GitHub",
                "Basic Commands of Everyday Git",
                "Git Branches",
                "Git Merge",
                "Git Merge Conflict",
                "Quiz & Hands-on-Labs",
            ]
        },
        {
            title: "CI/CD – Jenkins",
            lessons: [
                "What is Continuous Integration",
                "What is Continuous Deployment",
                "Continuous Integration Tools",
                "Installing and Configuring Jenkins",
                "How to implement CI/CD using Jenkins",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "Configuration Management",
            lessons: [
                "Configuration Management",
                "Challenges in Servers config management",
                "Configuration Management Tools – Chef, Puppet and Ansible",
                "Ansible Architecture and Process Flow",
                "Ansible Environment Setup",
                "Ansible Inventory and Configuration",
                "Ansible Modules",
                "Ansible Plays and Playbooks",
                "Ansible Roles",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "Introduction to Cloud Computing",
            lessons: [
                "Introduction to Cloud Computing",
                "Different cloud vendors",
                "What’s a server?",
                "What is serverless?",
                "Types of Cloud Computing – SaaS, IaaS, PaaS, FaaS",
                "Cloud — a Critical Factor for Business Success?"
            ]
        },
        {
            title: "AWS Core Services"
        },
        {
            title: "SDLC Automation",
            lessons: [
                "Introduction",
                "CodeCommit",
                "CodeBuild",
                "CodeDeploy",
                "CodePipeline",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "Configuration Management and Infrastructure as Code",
            lessons: [
                "Introduction",
                "AWS CloudFormation",
                "AWS Lambda & Step Functions",
                "AWS OpsWorks",
                "AWS Elastic Beanstalk",
                "AWS Config",
                "AWS ECS",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "Define and deploy monitoring, metrics, and logging systems",
            lessons: [
                "CloudWatch",
                "CloudTrail",
                "CloudWatch Events",
                "Configuring AWS CloudWatch Log Retention and Archiving",
                "Querying Logged Data",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "Policies and Standards Automation Services",
            lessons: [
                "Introduction",
                "AWS Service Catalog",
                "AWS Trusted Advisor",
                "AWS Systems Manager",
                "AWS Organizations",
                "AWS Secrets Manager",
                "Amazon Macie",
                "AWS Certificate Manager",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "Incident and Event Response Services",
            lessons: [
                "Introduction",
                "Amazon GuardDuty",
                "Amazon Inspector",
                "Amazon Kinesis",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "High Availability, Fault Tolerance, and Disaster Recovery",
            lessons: [
                "Introduction",
                "Multi-AZ versus Multi-Region architectures",
                "How to implement high availability, scalability, and fault tolerance",
                "Determine the right services based on business needs",
                "Determine how to design and automate disaster recovery strategies",
                "Evaluate a deployment for points of failure",
                "Quiz & Hands-on-Labs"
            ]
        },
        {
            title: "Mock AWS DevOps Certification Exam Questions / Answers"
        }
    ]
};