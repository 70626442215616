import { Component, OnInit } from '@angular/core';
import AOS from 'aos';

@Component({
  selector: 'app-services-support',
  templateUrl: './services-support.component.html',
  styleUrls: ['./services-support.component.scss']
})
export class ServicesSupportComponent implements OnInit {

  services = [
    {
      title: "Trianing Services",
      description: "Our training solutions are developed by subject matter experts to help organizations and individuals improve personal competence and skills. Upskilling helps an individual or organization stay competitive.",
      image: "assets/img/courses/online-training.jpg",
    },
    {
      title: "Project guidance for C.S.E students",
      description: "Projects that are research-based and industry oriented tremendously enlarges your job prospects. We also guide and help students so that they can make their project by themselves or under our guidance.",
      image: "assets/img/courses/academic-project.jpg",
    },
    // {
    //   title: "Hybrid Mobile App Development",
    //   description: "We offers the best mobile application development services for your business needs which includes strategic planning, development, app optimization, quality checks, technical support and maintenance.",
    //   image: "assets/img/courses/mobile-app-development.jpg",
    // },
    // {
    //   title: "Full Stack Development Services",
    //   description: "One-stop destination for frontend, backend development and database management services. We will help you in transforming ideas into reality.",
    //   image: "assets/img/courses/full-stack-development.jpg",
    // },
    // {
    //   title: "DevOps Services",
    //   description: "Increasing DevOps agility helps you experiencing the faster delivery speeds and a stable, reliable environment with enhanced collaboration between development & operations and stay ahead of the competition.",
    //   image: "assets/img/courses/devops.jpg",
    // },
    // {
    //   title: "Cloud Services",
    //   description: "Transform your products and services by leveraging scale, agility and pay as you go benefits of Cloud computing with our Cloud Engineering & Migration services",
    //   image: "assets/img/courses/cloud.jpg",
    // },
    // {
    //   title: "Blockchain Services",
    //   description: "Our services helps clients explore every aspect of blockchain and build tailored solutions designed to deliver value.",
    //   image: "assets/img/courses/blockchain.jpg",
    // },
    // {
    //   title: "Artificial Intelligence, Machine Learning Services & MLOps Services",
    //   description: "Our services create modern data and AI platforms to deliver trusted AI solutions in production and at scale. We also offer End-to-End MLOPs Services.",
    //   image: "assets/img/courses/machine-learning.jpg",
    // }
  ];

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
