import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-elearning-school',
    templateUrl: './elearning-school.component.html',
    styleUrls: ['./elearning-school.component.scss']
})
export class ElearningSchoolComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}