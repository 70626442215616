export default {
    title: "AWS Certified Cloud Practitioner Training",
    description: "This course is designed from absolute scratch and is intended for individuals who intend to begin their journey into AWS, whether you’re in a technical or non-technical role. In this course, you will learn core AWS services and use cases, billing and pricing models, security concepts, and how cloud impacts your business.",
    learningoutcomes: [
        {
            title: "After completion of this course, you will be able to demonstrate overall understanding of the AWS cloud platform"
        },
        {
            title: "AWS Certified Cloud Practitioner",
            link: "https://aws.amazon.com/certification/certified-cloud-practitioner/"
        }
    ],
    courserequirements: [
        "Familiarity with IT terminology and IT related work knowledge is required"
    ],
    coursestructure: [
        {
            title: "This is an eLearning course with interactive live sessions"
        },
        {
            title: "Course Duration",
            description: "4 Weeks (Monday - Friday daily 1 hour)"
        }
    ],
    modules: [
        {
            title: "Introduction to Cloud Computing",
            lessons: [
                "Introduction to Cloud Computing",
                "Different cloud vendors",
                "What’s a server?",
                "What is serverless?",
                "Types of Cloud Computing – SaaS, IaaS, PaaS, FaaS",
                "Cloud — a Critical Factor for Business Success?",
            ]
        },
        {
            title: "AWS Cloud concepts",
            lessons: [
                "AWS Global Infrastructure - Regions, Availability Zones(AZs) and Edge locations",
                "Interacting with AWS - AWS Console and AWS CLI",
                "AWS Well-Architected Framework",
                "Quiz",
            ]
        },
        {
            title: "Understanding of the core AWS services",
            lessons: [
                "Compute Services",
                "File Storage Services",
                "Database Services and Utilities",
                "Content and Network Delivery Services",
                "Application Integration Services",
                "Management and Governance Services",
                "Quiz",
            ]
        },
        {
            title: "Security and compliance within the AWS Cloud",
            lessons: [
                "Security and Architecture Overview",
                "Shared Responsibility Model",
                "Core security services",
                "Compliance",
                "Quiz",
            ]
        },
        {
            title: "Billing and Support Services",
            lessons: [
                "AWS Pricing Model",
                "AWS Budgets",
                "AWS Cost Explorer",
                "Resource Groups & Tagging",
                "AWS Organization & Consolidated Billing",
                "AWS Marketplace",
                "AWS Cost Calculators",
                "AWS Cost Optimization Best Practices",
                "Quiz",
            ]
        },
        {
            title: "Mock AWS Cloud Practitioner Certification Exam Questions / Answers"
        }
    ]
};