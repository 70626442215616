<div class="page-title-area align-items-center">

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="main-banner-content" data-aos="zoom-out-up" data-aos-delay="50" data-aos-duration="1000">
                    <h2 class="board-content"><span>{{course_content.title}}</span></h2>
                    <p class="board-content">{{course_content.description}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="faq-area">
    <div class="container">
        <div class="course-introduction">
            <span class="course-span">Pre-requisites</span>
            <ul>
                <li *ngFor="let courserequirement of course_content.courserequirements;">
                    <span>{{courserequirement}}</span>
                </li>
            </ul>
        </div>
        <div class="course-introduction">
            <span class="course-span">Course Structure</span>
            <ul>
                <li *ngFor="let coursestructure of course_content.coursestructure;">
                    <span *ngIf="!coursestructure.description">{{coursestructure.title}}</span>
                    <div *ngIf="coursestructure.description">
                        <span style="margin-right: 10px;">{{coursestructure.title}}:</span><span>{{coursestructure.description}}</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="course-introduction">
            <span class="course-span">Learning Outcomes</span>
            <ul>
                <li *ngFor="let learningoutcome of course_content.learningoutcomes;">
                    <span *ngIf="!learningoutcome.link">{{learningoutcome.title}}</span>
                    <a *ngIf="learningoutcome.link" [href]="learningoutcome.link" target="_blank">{{learningoutcome.title}}</a>
                </li>
            </ul>
        </div>
        <div class="course-dv">
            <span class="course-span">Course Content</span>
        </div>
        <div class="faq-accordion">
            <accordion>
                <accordion-group heading={{getModuleName(module,moduleIndex)}} *ngFor="let module of course_content.modules; index as moduleIndex;">
                    <p *ngFor="let lesson of module.lessons; index as lessionIndex;">
                        <span class="module-index">{{moduleIndex+1}}.{{lessionIndex+1}}</span>
                        <span>{{lesson.trim()}}</span>
                    </p>
                    <span *ngIf="module.labs !== null && module.labs !== undefined" class="hand-on-lab-span">Hands-on Labs</span>
                    <ul *ngIf="module.labs !== null && module.labs !== undefined">
                        <li *ngFor="let lab of module.labs; index as labIndex;">{{lab}}</li>
                    </ul>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>