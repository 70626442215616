<div class="container">
    <div class="section-title">
        <h4>Affordable Online Courses and Learning Opportunities​</h4>
        <p>Finding your own space and utilize better learning options can result in faster than the traditional ways. Enjoy the beauty of eLearning!</p>
    </div>
    <div class="row">
        <div class="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-brain-process"></i>
                </div>
                <h6>Learn the Latest Top Skills</h6>
                <p>Learning top skills can bring an extra-ordinary outcome in a career.</p>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-computer"></i>
                </div>
                <h6>Flexible learning program</h6>
                <p>Learn at your own pace and reach your personal goals on the schedule that works best for you.</p>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-shield-1"></i>
                </div>
                <h6>Learn From Industry Experts</h6>
                <p>Experienced teachers can assist in learning faster with their best approaches!</p>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-md-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
            <div class="single-features-box">
                <div class="icon">
                    <i class="flaticon-world"></i>
                </div>
                <h6>Enjoy Learning From Anywhere</h6>
                <p>We are delighted to give you options to enjoy learning from anywhere in the world.</p>
            </div>
        </div>
    </div>
</div>