<div class="page-title-area">
    <div class="container" style="height: 60px;">
        <div class="header-dv">
            <h2 style="text-align: center;" data-aos="zoom-out-up" data-aos-delay="50" data-aos-duration="1000"><span class="page-title-content-span">Services</span></h2>
        </div>

    </div>
</div>

<div class="courses-area pt-70 pb-60">
    <div class="container">
        <div class="row">
            <div data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000" class="col-lg-4 col-md-6" *ngFor="let service of services; index as serviceIndex;">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <img [src]="service.image" alt="image">
                    </div>
                    <div class="courses-content">
                        <h6 class="courses-title">{{service.title}}</h6>
                        <p *ngIf="service.description !== undefined && service.description !== null">
                            {{service.description}}
                        </p>
                        <!-- <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li></li>
                            <li>Readmore</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>