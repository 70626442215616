import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ElearningSchoolComponent } from './components/pages/elearning-school/elearning-school.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesSupportComponent } from './components/pages/services/services-support.component';
import { CourseContentomponent } from './components/pages/course-content/course-content.component';

const routes: Routes = [
    {path: '', component: ElearningSchoolComponent},
    {path: 'coming-soon/:code', component: ComingSoonComponent},
    {path: 'services', component: ServicesSupportComponent},
    {path: 'contact', component: ContactUsComponent},
    {path: 'course-content/:code', component: CourseContentomponent},
    {path: '**', component: ErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }