<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/upskill/logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">AWS Cloud  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/course-content', 'aws-cloud-practitioner']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Cloud Practitioner</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon','aws-cloud-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Developer-Associate</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'aws-solutions-architect']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Solutions Architect–Associate</a></li>
                                    <li class="nav-item"><a [routerLink]="['/course-content', 'aws-devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified DevOps Engineer-Professional</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'aws-security-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Security-Specialty</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Google Cloud  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'gcp-cloud-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Associate Cloud Engineer</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon','gcp-cloud-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud Developer</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'gcp-cloud-architect']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud Architect</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'gcp-devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud DevOps Engineer</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'gcp-security-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud Security Engineer</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Azure Cloud  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'azure-fundamentals']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Fundamentals(AZ-900)</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon','azure-developer-associate']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Developer Associate(AZ-204)</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'azure-devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Designing and Implementing DevOps Solutions(AZ-400)</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'azure-architect-technologies']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Architect Technologies(AZ-303)</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'azure-architect-design']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Architect Design(AZ-304)</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'azure-security-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Security Technologies(AZ-500)</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Data Science  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'machine-learning']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Machine Learning with Python</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'deep-learning']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Deep Learning with Python</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'full-stack-datascience']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Full Stack Data Science with Python</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Full Stack Web Devlopment <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'mean-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MEAN Stack</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'mern-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MERN Stack</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'microsoft-dotnet-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Microsoft .Net Stack</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'django-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Python and Django Stack</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'flask-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Python and Flask Stack</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Restful API Development <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'nodejs-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NodeJS/ExpressJS API</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'nestjs-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NestJS API</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'flask-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Python Flask API</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'dotnet-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">.Net Web API</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'golang-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Golang API</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">DevOps <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">DevOps Engineer</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'site-reliability-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Site Reliablity Engineer</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Hybrid Mobile App Development  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'react-native-app-development']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">React Native App Development</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'ionic-app-development']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Ionic App Development</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'flutter-app-development']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Flutter App Development</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blockchain  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'blockchain-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Blockchain Developer</a></li>
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'blockchain-architect']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Blockchain Architect</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link">RPA<i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a [routerLink]="['/coming-soon', 'uipath-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">UI Path Developer</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home</a> </li>
                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleCourses()">Courses<i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu" *ngIf="showCourses">
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleAWSCloud()">AWS Cloud  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showAwsCloud">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/course-content', 'aws-cloud-practitioner']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Cloud Practitioner</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon','aws-cloud-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Developer-Associate</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'aws-solutions-architect']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Solutions Architect–Associate</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/course-content', 'aws-devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified DevOps Engineer-Professional</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'aws-security-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Security-Specialty</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleGCPCloud()">Google Cloud  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showGCPCloud">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'gcp-cloud-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Associate Cloud Engineer</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon','gcp-cloud-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud Developer</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'gcp-cloud-architect']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud Architect</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'gcp-devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud DevOps Engineer</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'gcp-security-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Professional Cloud Security Engineer</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleAzureCloud()">Azure Cloud  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showAzureCloud">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'azure-fundamentals']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Fundamentals(AZ-900)</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon','azure-developer-associate']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Developer Associate(AZ-204)</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'azure-devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Designing and Implementing DevOps Solutions(AZ-400)</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'azure-architect-technologies']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Architect Technologies(AZ-303)</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'azure-architect-design']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Architect Design(AZ-304)</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'azure-security-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Security Technologies(AZ-500)</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleDatascience()">Data Science  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showDataScience">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'machine-learning']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Machine Learning with Python</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'deep-learning']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Deep Learning with Python</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'full-stack-datascience']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Full Stack Data Science with Python</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleFullstackDevelopment()">Full Stack Web Devlopment <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showFullStackDevelopment">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'mean-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MEAN Stack</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'mern-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MERN Stack</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'microsoft-dotnet-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Microsoft .Net Stack</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'django-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Python and Django Stack</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'flask-stack']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Python and Flask Stack</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleRestAPI()">Restful API Development <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showRestAPI">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'nodejs-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NodeJS/ExpressJS API</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'nestjs-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">NestJS API</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'flask-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Python Flask API</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'dotnet-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">.Net Web API</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'golang-api']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Golang API</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleDevOps()">DevOps <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showDevOps">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'devops-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">DevOps Engineer</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'site-reliability-engineer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Site Reliablity Engineer</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleHybridMobileDevelopment()">Hybrid Mobile App Development  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showHybridDevelopment">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'react-native-app-development']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">React Native App Development</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'ionic-app-development']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Ionic App Development</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'flutter-app-development']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Flutter App Development</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleBlockChain()">Blockchain  <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showBlockchain">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'blockchain-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Blockchain Developer</a></li>
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'blockchain-architect']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certified Blockchain Architect</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link" (click)="toggleRPA()">Robotic Process Automation <i class='bx bx-chevron-right'></i></a>
                                <ul class="dropdown-menu" *ngIf="showRPA">
                                    <li class="nav-item"><a (click)="toggleClass()" [routerLink]="['/coming-soon', 'uipath-developer']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">UI Path Developer</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Services</a></li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Contact</a></li>
                </ul>
            </div>
        </nav>
    </div>
</div>