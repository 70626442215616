<div class="main-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="main-banner-content" data-aos="zoom-out-up" data-aos-delay="50" data-aos-duration="1000">
                    <h1 class="board-text"><span>Don't work hard. Upskill & work smart</span></h1>
                    <h2 class="board-content"><span>Flexible Study at Your Own Pace, According to Your Own Needs</span></h2>
                    <p class="board-content">Our training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives
                        will help you to learn better and quicker than the traditional ways of learning skills</p>

                </div>
            </div>
        </div>
    </div>
</div>